import React, { useState } from 'react';
import image1 from "../assets/frontendassets/first-img1.png";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaLaptopCode, FaCode, FaBullhorn, FaChartLine } from "react-icons/fa";
import Header from './Comman/Header';
import Footer from './Comman/Footer';
import img1 from '../assets/frontendassets/webd1.png'
import img2 from '../assets/frontendassets/dd.png'
import img3 from '../assets/frontendassets/digital.png'
import img4 from '../assets/frontendassets/stargy.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import i4 from '../assets/frontendassets/s1.jpg'
import i5 from '../assets/frontendassets/s2.jpg'
import i6 from '../assets/frontendassets/s3.jpg'

interface Product {
  _id: string;
  userId: string;
  name: string;
  description: string;
  productImage: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}
interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}
interface Services{
  _id: string;
  name: string;
  description: string;
  price: number;
  serviceImage: string;
}

interface ThemeData {
  _id: string;
  userId: string;
  templateId: string;
  header: {
    themecolore: string;
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    logo: string | null;
    _id: string;
  };
  section: {
    title: string;
    description: string;
    banner: string | null;
    _id: string;
  };
  about: {
    title: string;
    description: string;
    image: string | null;
    _id: string;
  };

  footer: {
    about: string;
    phone: string;
    email: string;
    address: string;
    instagram: string;
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  domainName: string;
}

interface Theme3Props {
  data: {
    template: ThemeData;
    service: Services[];
   team: Team[];
  };
}

const Theme3Homepage: React.FC<Theme3Props> = ({ data }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const productImageUrl = process.env.REACT_APP_IMAGE_URL + '/products/';
  const serviceImageUrl = process.env.REACT_APP_IMAGE_URL + '/service/';
  const profileImageUrl = process.env.REACT_APP_IMAGE_URL + '/team/';

  const [expandedProductId, setExpandedProductId] = useState<string | null>(null);

  const toggleDescription = (id: string) => {
    setExpandedProductId(expandedProductId === id ? null : id);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display 3 cards at once (you can adjust as needed)
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1, // Show 1 card on mobile view
          slidesToScroll: 1,
          infinite: true,
          dots: true,

        },
      },
    ],
  };
  const truncateDescription = (description: string, expanded: boolean) => {
    if (expanded || description.length <= 100) {
      return description;
    }
    return description.substring(0, 100) + '...';
  };

  const hederData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    templateId: data.template.templateId,
  }

  const footerData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    footer: data.template.footer,
    templateId: data.template.templateId,
  }

  let BGColor = "#FFFFFF";
  let TextColor = "#FFFFFF";
  let HoverColor = "#fd9a2d";
  let ThemeColor = "#fdac53";
  let fontFamily = "Raleway, sans-serif";

  const rootKeys = Object.keys(data);
  // Extract the keys from the 'template' object
  const templateKeys = Object.keys(data.template);
  // Find specific keys from the data object
  const keysToFind = ['about', 'products', 'team'];

  // const foundKeys: string[] = [];
  // foundKeys.push('home');

  // keysToFind.forEach(key => {
  //   if (templateKeys.includes(key)) {
  //     foundKeys.push(key);
  //   } else if (rootKeys.includes(key)) {
  //     foundKeys.push(key);
  //   }
  // });

  // foundKeys.push('contact');

  return (
    <>
    <div className='overflow-x-hidden'>
      <Header hederData={hederData} />
      <style>
        {`
        *{
          font-family: ${fontFamily}
        }
        .about-us section-title{
          color:
        }
        `}
      </style>

      {/* Hero Section */}
      <section id="home" className="relative" style={{ color: TextColor }}>
  <div
    className="relative-container h-full bg-cover bg-right bg-custom-white-opacity mx-auto px-4 lg:px-32 py-12 lg:py-20 flex flex-col items-start text-start"
    style={{
      backgroundImage: `url(${imageUrl}${data.template.section.banner})`,
      backgroundSize: 'cover', // Ensure the background image covers the section
      backgroundPosition: 'center', // Center the background image
    }}
  >
    <p className="z-10 uppercase leading-4 text-xl md:text-2xl font-semibold mb-4 lg:mb-6 w-full lg:w-1/2 mt-2 text-center lg:text-left">
      {data.template.section.title}
    </p>
    <h3 className="z-10 text-2xl md:text-4xl lg:text-6xl font-bold mb-4 lg:mb-6 w-full lg:w-1/2 text-center lg:text-left">
      {data.template.section.description}
    </h3>
    <a
      href="#"
      className="z-10 bg-[#fdac53] hover:bg-[#fd9a2d] text-white font-semibold rounded py-3 px-6 md:px-8 lg:px-10 mt-4 lg:mt-5 block text-center lg:text-left"
    >
      {data.template.header.buttonText}
    </a>
  </div>
</section>





      <section id='about' className="py-12 about-us">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-5 items-center gap-8">
            {/* Adjusted grid columns for smaller image part */}
            <div className="relative md:col-span-2">
              {/* Image container now spans 2 columns on medium screens and above */}
              <img
                src={`${imageUrl}${data.template.about.image}`}
                alt="Man working on computer"
                className="w-full rounded-md shadow-md border-4 border-white h-full"
              // style={{ borderImage: `url('https://www.example.com/path/to/border-image.png') 30 stretch` }} 
              /* Optional: Use a border image for more customization */
              />
            </div>
            <div className="md:col-span-3">
              {/* Text container now spans 3 columns on medium screens and above */}
              <h2
                className={`section-title text-lg text-white bg-[${ThemeColor}] font-bold mb-4 inline p-1 uppercase`}
              >
                Who We Are
              </h2>
              <h1 className="heading text-2xl md:text-4xl font-black my-4">
                {/* Increased text size for smaller and larger screens */}
               {data.template.about.title} 
              </h1>
              <p className="description text-base md:text-lg text-[#4a4a4a] mb-6">
                {/* Increased text size for smaller and larger screens */}
               {data.template.about.description}
              </p>
              {/* <button
                className={`bg-[${ThemeColor}] text-[#212529] hover:bg-[${HoverColor}] font-medium py-2 px-4 rounded`}
              >
                Read More
              </button> */}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-12 mt-12 px-6">
            <div className="flex items-center bg-white p-4 border rounded-md shadow-sm">
              <FaMapMarkerAlt className="text-orange-500 text-3xl mr-6" />
              <div>
                <p className="font-bold">Our Office</p>
                <p>{footerData.footer.address}</p>
              </div>
            </div>
            <div className="flex items-center bg-white p-4 border rounded-md shadow-sm">
              <FaEnvelope className="text-orange-500 text-3xl mr-6" />
              <div>
                <p className="font-bold">Email Us</p>
                <p>{footerData.footer.email}</p>
              </div>
            </div>
            <div className="flex items-center bg-white p-6 border rounded-md shadow-sm">
              <FaPhoneAlt className="text-orange-500 text-3xl mr-6" />
              <div>
                <p className="font-bold">Call Us</p>
                <p>{footerData.footer.phone}</p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <div id='products' className="max-w-7xl mx-auto p-4 md:p-8 py-16 grid grid-cols-1 md:grid-cols-2 gap-10">
        {/* Left Section */}
        <div className="flex flex-col justify-center max-w-md mx-auto md:mx-0">
          <h3 className="text-orange-500 uppercase text-sm font-bold mb-2">What We Do</h3>
          <h2 className="text-5xl font-bold mb-4">We Offer Creative Services</h2>
          <p className="text-2xl text-gray-500 mb-6">
            Lorem ut kasd dolores elitr sed est duo ea ipsum justo diam, est erat lorem. Est magna sea clita diam tempor elitr.
          </p>
          {/* <button className="bg-orange-500 text-white py-2 px-4 rounded-lg shadow-lg w-32">
            Discover More
          </button> */}
        </div>
       
        {/* Right Section */}
        {data?.service?.length > 0 ? (
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mr-10">
    {data.service.map((service: Services, index: number) => (
      <div key={index} className="text-center sm:text-left border border-gray-200 p-4 shadow">
        <div className="flex items-center justify-center sm:justify-start mb-4">
          <span className="bg-orange-100 p-3 rounded-full">
            <img src={`${serviceImageUrl}${service.serviceImage}`} alt={`${service.name} Icon`} className="w-8 h-8" />
          </span>
        </div>
        <h4 className="text-xl font-semibold mb-2">{service.name}</h4>
        <p className="text-gray-500">{service.description}</p>
      </div>
    ))}
  </div>
) : (
  <p>No services available.</p>
)}


      </div>

{/*dds 
 dil sambh dil sa

*/}


      <section  id="team" className="py-12 px-4">
  <div className="container mx-auto px-4">
    <div className="flex flex-col md:flex-row items-center">
      <div className="md:w-2/3 mb-8 md:mb-0">
        <h5 className="text-orange-600 uppercase text-sm font-semibold mb-2">Meet the Team</h5>
        <h2 className="text-6xl font-bold mb-4">Meet Experts Behind the Work</h2>
        <p className="text-2xl text-gray-600 mb-6">
          Eirmod diam magna sed sea rebum, elitr diam dolor lorem ipsum, ipsum stet magna ea diam vero stet vero
        </p>
        <button className="bg-orange-500 text-white py-2 px-4 rounded-md">Meet All Experts</button>
      </div>
      <div className="w-full md:w-1/2">
  <Slider {...sliderSettings}>
    {data.team.map((member) => (
      <div key={member._id} className="text-center border border-gray-300 p-4 rounded-md mx-2 w-full md:w-52">
        <img
          className="w-24 h-24 md:w-32 md:h-32 rounded-md object-cover mb-4 mx-auto"
          src={`${profileImageUrl}${member.profileImage}`}
          alt={member.name}
        />
        <h3 className="text-lg md:text-xl font-semibold">{member.name}</h3>
        <p className="text-sm md:text-base text-gray-500">{member.description}</p>
      </div>
    ))}
  </Slider>
</div>
    </div>
  </div>
</section>
      </div>
      <Footer footerData={footerData} />
    </>
    
  );
};

export default Theme3Homepage;
