import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ThemePageProps {
  hederData: {
    logo: any;
    title: any;
    themeColor: string;
    templateId: string;
  };
  navManu?: any; // Mark as optional
}

const Header: React.FC<ThemePageProps> = ({ hederData, navManu }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  let BGColor: string;
  let TextColor: string;
  let ThemeColor: string;
  let isStickySameColor: boolean;
  let fontFamily: string;
  let stickyBGColor: string = ""; // Initialize with a default value
  let stickyColor: string = "";

  const location = useLocation();
  const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1); // This will give you "amzadkhan"
  // console.log(location.hash);
  const sectionId = location.hash ? location.hash.substring(1) : '';
  const [activeSection, setActiveSection] = useState(sectionId);
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3 // 30% of the section should be visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  let menus = [];

  switch (hederData?.templateId) {
    case '2':
      BGColor = "#FFFFFF";
      TextColor = "#3c424f";
      ThemeColor = "#ff6c8d";
      isStickySameColor = true;
      fontFamily = '"PT Serif", serif';

      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "products": "Products" },
        { "team": "Team" },
        { "service": "Service" },
        { "contact": "Contact" },
      ]
      break;
    case '3':
      BGColor = "#FFFFFF";
      TextColor = "#131313";
      ThemeColor = "#fdac53";
      fontFamily = "Raleway, sans-serif";

      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "service": "Service" },
        { "team": "Team" },
        { "contact": "Contact" },
      ]
      break;
    case '4':
      BGColor = "#FFFFFF";
      TextColor = "#131313";
      ThemeColor = "000000";
      fontFamily = "var(--font-rubik), sans-serif";

      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "products": "Dishes" },
        { "team": "Chefs" },
        { "contact": "Contact" },
      ]
      break;
    case '5':
      BGColor = "#FFFFFF";
      TextColor = "#131313";
      ThemeColor = "000000";
      fontFamily = "Lexend, sans-serif";

      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "products": "Courses" },
        { "team": "Instructors" },
        { "contact": "Contact" },
      ]
      break;
    default:
      BGColor = "#FFFFFF";
      TextColor = "#000000";
      ThemeColor = "#000000";
      fontFamily = "Lexend, sans-serif";

      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "service": "Service" },
        { "team": "Doctors" },
        { "contact": "Contact" },
      ]
      break;
  }

  ThemeColor = hederData?.themeColor;

  // if (hederData || hederData != null || hederData != "") {

  //   if (hederData.templateId == "1") {
  //     BGColor = "#FFFFFF";
  //     TextColor = "#000000";
  //     ThemeColor = "#000000";
  //     isStickySameColor = false;
  //     stickyColor = "";
  //     stickyBGColor = "";
  //     fontFamily = "Arial, sans-serif";

  //     menus = [
  //       { "home": "Home" },
  //       { "about": "About" },
  //       { "service": "Service" },
  //       { "team": "Doctors" },
  //       { "contact": "Contact" },
  //     ]
  //   }
  //   if (hederData.templateId == "2") {
  //     BGColor = "#FFFFFF";
  //     TextColor = "#3c424f";
  //     ThemeColor = "#ff6c8d";
  //     isStickySameColor = true;
  //     fontFamily = '"PT Serif", serif';

  //     menus = [
  //       { "home": "Home" },
  //       { "about": "About" },
  //       { "products": "Products" },
  //       { "team": "Team" },
  //       { "service": "Service" },
  //       { "contact": "Contact" },
  //     ]
  //   }
  //   if (hederData.templateId == "3") {
  //     BGColor = "#FFFFFF";
  //     TextColor = "#131313";
  //     ThemeColor = "#fdac53";
  //     isStickySameColor = false;
  //     stickyColor = "#FFFFFF";
  //     stickyBGColor = "#131313";
  //     fontFamily = "Raleway, sans-serif";

  //     menus = [
  //       { "home": "Home" },
  //       { "about": "About" },
  //       { "service": "Service" },
  //       { "team": "Team" },
  //       { "contact": "Contact" },
  //     ]
  //   }
  //   if (hederData.templateId == "4") {
  //     BGColor = "#FFFFFF";
  //     TextColor = "#131313";
  //     ThemeColor = "000000";
  //     isStickySameColor = false;
  //     stickyColor = "#FFFFFF";
  //     stickyBGColor = "#131313";
  //     fontFamily = "var(--font-rubik), sans-serif";

  //     menus = [
  //       { "home": "Home" },
  //       { "about": "About" },
  //       { "products": "Dishes" },
  //       { "team": "Chefs" },
  //       { "contact": "Contact" },
  //     ]
  //   }
  //   if (hederData.templateId == "5") {
  //     BGColor = "#FFFFFF";
  //     TextColor = "#131313";
  //     ThemeColor = "000000";
  //     isStickySameColor = false;
  //     stickyColor = "#FFFFFF";
  //     stickyBGColor = "#131313";
  //     fontFamily = "var(--font-rubik), sans-serif";

  //     menus = [
  //       { "home": "Home" },
  //       { "about": "About" },
  //       { "products": "Courses" },
  //       { "team": "Instructors" },
  //       { "contact": "Contact" },
  //     ]
  //   }

  //   ThemeColor = hederData.themeColor;
  // }

  // console.log(hederData)
  const headerStyles = {
    backgroundColor: BGColor,
    color: TextColor,
    fontFamily: fontFamily,
  };

  return (
    <header className={`font-pt-serif transition-colors sticky top-0 z-50`} style={headerStyles}>
      <style>
        {`
          .nav-link:hover {
            color: ${ThemeColor};
          }
          .active-nav-link {
            color: ${ThemeColor} !important;
            font-weight: bold;
          }
        `}
      </style>
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a className="flex title-font font-medium items-center mb-4 md:mb-0">
          {hederData.logo && (
            <img
              src={`${imageUrl}${hederData.logo}`}
              alt="Logo"
              className="w-12 h-12 rounded-full bg-gray-700"
            />
          )}
          <span className="ml-3 text-3xl font-bold">{hederData.title}</span>
        </a>
        <nav className={`md:ml-auto flex flex-wrap items-center justify-center text-[16px]`}>
          {menus.map((menu, index) => {
            const [key, value] = Object.entries(menu)[0]; // Access the first entry in each object
            return (
              <a
                href={`#${key}`} // Using the key for the href attribute
                className={`nav-link mr-6 p-1 rounded-lg ${activeSection === key ? 'active-nav-link' : ''}`}
                key={key} // Use the key for the key prop as well
                onClick={() => setActiveSection(key)}
              >
                {value}
              </a>
            );
          })}
        </nav>
      </div>
    </header>
  );
}

export default Header;
