import React, { useState } from 'react';
import { FaApple, FaChevronRight, FaFacebook, FaGooglePlay, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Header from './Comman/Header';
import Footer from './Comman/Footer';
import { FaBowlFood, FaChevronLeft } from "react-icons/fa6";
import { PiBowlFoodFill } from "react-icons/pi";
import { IoFastFoodSharp } from "react-icons/io5";
import { RiDrinks2Fill } from "react-icons/ri";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';




interface Product {
  _id: string;
  userId: string;
  name: string;
  description: string;
  productImage: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}
interface ThemeData {
  _id: string;
  userId: string;
  templateId: string;
  header: {
    themecolore: string;
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    logo: string | null;
    _id: string;
  };
  section: {
    title: string;
    description: string;
    banner: string | null;
    _id: string;
  };
  about: {
    title: string;
    description: string;
    image: string | null;
    _id: string;
  };
  footer: {
    themecolore: string;
    about: string;
    phone: string;
    email: string;
    address: string;
    instagram: string;
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  domainName: string;
}

interface ThemePageProps {
  data: {
    template: ThemeData;
    products: Product[];
    team: Team[];
  };
}

const ThemePage4: React.FC<ThemePageProps> = ({ data }) => {
  const [expandedProduct, setExpandedProduct] = useState<string | null>(null);

  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const productImageUrl = process.env.REACT_APP_IMAGE_URL + '/products/';
  const teamImageUrl = process.env.REACT_APP_IMAGE_URL + '/team/';

  const handleToggleDescription = (productId: string) => {
    setExpandedProduct(expandedProduct === productId ? null : productId);
  };

  const hederData: any = {
    templateId: data.template.templateId,
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
  }

  const footerData: any = {
    templateId: data.template.templateId,
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    footer: data.template.footer,
  }
  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: <FaChevronLeft color={data.template.header.themecolore} size="2rem" className='absolute top-1/2 left-[-50px] transform -translate-y-1/2' />, // Custom previous arrow
    nextArrow: <FaChevronRight color={data.template.header.themecolore} size="2rem" />, // Custom next arrow
    speed: 500,
    slidesToShow: 4, // Number of slides to show at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const rootKeys = Object.keys(data);
  // Extract the keys from the 'template' object
  const templateKeys = Object.keys(data.template);
  // Find specific keys from the data object
  const keysToFind = ['about', 'products', 'team'];

  // const foundKeys: string[] = [];
  // foundKeys.push('home');

  // keysToFind.forEach(key => {
  //   if (templateKeys.includes(key)) {
  //     foundKeys.push(key);
  //   } else if (rootKeys.includes(key)) {
  //     foundKeys.push(key);
  //   }
  // });

  // foundKeys.push('contact');

  return (
    <>
      {/* <header className="bg-green-800 text-white">
        <div className="container mx-auto flex flex-col md:flex-row items-center py-6 px-4 md:px-6">
          <a href="/" className="flex items-center mb-4 md:mb-0">
            {data.template.header.logo ? (
              <img
                src={`${imageUrl}${data.template.header.logo}`}
                alt="Logo"
                className="w-12 h-12 rounded-full bg-gray-700"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-12 h-12 rounded-full bg-gray-700"
                viewBox="0 0 24 24"
              >
                <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
              </svg>
            )}
            <span className="ml-3 text-2xl font-semibold">{data.template.header.title}</span>
          </a>
        </div>
      </header> */}
      <Header hederData={hederData}  />
      <section id='home' className="bg-gray-50 flex flex-col md:flex-row items-center justify-between p-8 md:p-16">
        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left -mt-120">
          <h1 className="text-6xl md:text-7xl font-bold text-gray-900 leading-tight">
            {data.template.section.title}
          </h1>

          <p className="text-gray-600 mt-4 font-medium">
            {data.template.section.description}    </p>
          <div className="mt-8 flex flex-col md:flex-row items-center justify-center md:justify-start space-y-4 md:space-y-0 md:space-x-4">
            <a href="#" className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 font-bold py-3 px-6 rounded-full flex items-center">

              {data.template.header.buttonText}
            </a>
            {/* <a href="#" className="bg-gray-100 hover:bg-gray-200 text-gray-900 font-bold py-3 px-6 rounded-full flex items-center">
        About us
      </a> */}
          </div>
        </div>

        {/* Image Section */}
        <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center md:justify-end relative mb-[-100px]">
          <img
            src={data.template.section.banner ? `${imageUrl}${data.template.section.banner}` : "https://dummyimage.com/720x600"}
            alt="A person with salad"
            className="w-full md:w-3/4 lg:w-1/2 rounded-full object-cover"
          />
          {/* Background shapes */}
          {/* <div className="absolute top-0 left-0 w-16 h-16 bg-yellow-400 rounded-full"></div>
    <div className="absolute bottom-0 right-0 w-10 h-10 bg-gray-100 rounded-full"></div> */}
        </div>
      </section>





      <div className="flex flex-col md:flex-row items-center justify-between bg-gray-50 py-12 px-6 md:px-12">
        {/* Image Section */}
        <div className="relative w-full md:w-1/2">
          <img
            src={data.template.about.image ? `${imageUrl}${data.template.about.image}` : "https://dummyimage.com/720x600"}
            alt="Experience"
            className="rounded-lg shadow-lg object-cover w-full h-full transition-transform transform hover:scale-105"
          />
          <div className="absolute bottom-4 left-4 bg-white p-4 shadow-md rounded-lg">
            <h2 className="text-4xl font-bold">17</h2>
            <p className="text-lg">Years Experience</p>
          </div>
        </div>

        {/* Text Section */}
        <div id='about' className="w-full md:w-1/2 mt-8 md:mt-0 md:ml-12">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-800">
            {data.template.about.title}
          </h2>
          <div className="mt-6 space-y-6">
            {/* Feature 1 */}
            <div className="flex items-start">
              {/* <div className="text-yellow-500 text-3xl font-bold mr-4">01</div> */}
              <div>
                {/* <h3 className="text-xl font-bold text-gray-800">
                We are located in the city center
              </h3> */}
                <p className="text-gray-600 font-semibold">
                  {data.template.about.description}
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            {/* <div className="flex items-start">
            <div className="text-yellow-500 text-3xl font-bold mr-4">02</div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">
                Fresh, organic ingredients
              </h3>
              <p className="text-gray-600">
                Consectetur numquam porro nemo veniam necessitatibus praesentium eligendi rem temporibus adipisci quo modi.
              </p>
            </div>
          </div> */}

            {/* Feature 3 */}
            {/* <div className="flex items-start">
            <div className="text-yellow-500 text-3xl font-bold mr-4">03</div>
            <div>
              <h3 className="text-xl font-bold text-gray-800">
                Own fast delivery
              </h3>
              <p className="text-gray-600">
                Necessitatibus praesentium eligendi rem temporibus adipisci quo modi. Lorem ipsum dolor sit.
              </p>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-yellow-500">
            What do you like today?
          </h2>
          <p className="mt-2 text-base text-gray-500">
            Consectetur numquam poro nemo veniam eligendi rem adipisci quo modi.
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
          {/* Card 1: Starters */}
          <div className="bg-gray-100 rounded-lg p-6 flex items-center">
            <div className="flex-shrink-0 hover:-translate-y-1 transition-transform duration-200">
              <FaBowlFood className="text-yellow-500 text-4xl" />
            </div>
            <div className="ml-6">
              <h3 className="text-lg font-extrabold text-gray-900">Starters</h3>
              <p className="mt-2 text-base text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>
          </div>

          {/* Card 2: Main dishes */}
          <div className="bg-gray-100 rounded-lg p-6 flex items-center">
            <div className="flex-shrink-0  hover:-translate-y-1 transition-transform duration-200">
              <IoFastFoodSharp className="text-yellow-500 text-4xl" />
            </div>
            <div className="ml-6">
              <h3 className="text-lg font-extrabold text-gray-900">Main dishes</h3>
              <p className="mt-2 text-base text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>
          </div>

          {/* Card 3: Drinks */}
          <div className="bg-gray-100 rounded-lg p-6 flex items-center">
            <div className="flex-shrink-0  hover:-translate-y-1 transition-transform duration-200">
              <RiDrinks2Fill className="text-yellow-500 text-4xl" />
            </div>
            <div className="ml-6">
              <h3 className="text-lg font-extrabold text-gray-900">Drinks</h3>
              <p className="mt-2 text-base text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>
          </div>

          {/* Card 4: Desserts */}
          <div className="bg-gray-100 rounded-lg p-6 flex items-center">
            <div className="flex-shrink-0  hover:-translate-y-1 transition-transform duration-200">
              <PiBowlFoodFill className="text-yellow-500 text-4xl" />
            </div>
            <div className="ml-6">
              <h3 className="text-lg font-extrabold text-gray-900">Desserts</h3>
              <p className="mt-2 text-base text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipisicing.
              </p>
            </div>
          </div>
        </div>
        {/* 
      <div className="mt-10 flex justify-center">
        <button className="bg-yellow-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-yellow-600">
          Go shopping now
        </button>
      </div> */}
      </div>

      <section id='products' className="text-gray-600 body-font">
        <div className="container mx-auto px-5 py-24">
        <div className="flex flex-col items-start text-left w-full mb-20">
  <h1 className="sm:text-4xl text-4xl font-bold title-font text-yellow-500 mb-5">
    Most popular dishes
  </h1>
  <p className="lg:w-2/3 leading-relaxed text-base font-semibold">
    Explore our range of top-quality products designed to meet your needs.
  </p>
</div>

          <div className="flex flex-wrap -m-4">
            {data.products.map((product) => (
              <div key={product._id} className="lg:w-1/4 md:w-1/2 p-4 w-full">
                <div className="relative bg-white-500 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
                  <img
                    alt="product"
                    className="object-cover object-center w-full h-48 md:h-56 block"
                    src={`${productImageUrl}${product.productImage}`}
                  />
                  <div className="p-6">
                    <h2 className="text-1xl font-bold text-gray-900 truncate">{product.name}</h2>
                    <p className="mt-2 text-gray-600 text-sm">
                      {product.description.length > 100
                        ? (expandedProduct === product._id
                          ? product.description
                          : `${product.description.substring(0, 100)}...`)
                        : product.description}
                    </p>
                    {product.description.length > 100 && (
                      <button
                        onClick={() => handleToggleDescription(product._id)}
                        className="mt-2 text-yellow-500 hover:text-yellow-600 focus:outline-none"
                      >
                        {expandedProduct === product._id ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id='team' className="py-10">
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-bold text-gray-800 mb-8">They will cook for you</h2>
    <p className="text-gray-600 mb-12 text-xl">Consequatur numquam porro nemo veniam eligendi rem adipisci quo modi.</p>
    
    {/* Slider implementation */}
    <Slider {...sliderSettings}>
  {data.team.map((member, index) => (
    <div key={index} className="p-4"> {/* Added padding to handle spacing in slider */}
      <div className="bg-yellow-500 shadow-lg rounded-lg overflow-hidden">
        <div className="relative h-64"> {/* Fixed height */}
          <img
            src={`${teamImageUrl}${member.profileImage}`}
            alt={member.name}
            className="w-full h-full object-contain transition-transform transform hover:scale-105" // Use object-contain
          />
        </div>
        <div className="text-center p-6">
          <h3 className="text-xl font-semibold text-gray-900">{member.name}</h3>
          <p className="text-gray-600">{member.description}</p>
        </div>
      </div>
    </div>
  ))}
</Slider>

  </div>
</section>





      <Footer footerData={footerData} />

    </>
  );
};

export default ThemePage4;
