import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import sectionDivider from './images/section-divider-icon.png';
import About from './images/about-bg-image.png'
import BeforImage from './images/before.jpg'
import AfterImage from './images/after.jpg'
import Header from './Comman/Header';
import Footer from './Comman/Footer';
import './Theme2.css'

let BGColor = "#FFFFFF";
let TextColor = "#686b77";
let ThemeColor = "#ff6c8d";
let TitleColor = "#3c424f";

interface Product {
  price: number;
  _id: string;
  userId: string;
  name: string;
  description: string;
  productImage: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Services {
  _id: string;
  name: string;
  description: string;
  price: number;
  serviceImage: string;
}

interface Client {
  id: string;
  clientImage: string
}
interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}

interface ThemeData {
  _id: string;
  userId: string;
  templateId: string;
  header: {
    themecolore: string;
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    logo: string | null;
    _id: string;
  };
  about: {
    title: string;
    description: string;
    image: string | null;
  }
  section: {
    title: string;
    description: string;
    banner: string | null;
    _id: string;
  };
  footer: {
    about: string;
    phone: string;
    email: string;
    address: string;
    instagram: string;
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  domainName: string;
}

interface Theme2Props {
  data: {
    template: ThemeData;
    products: Product[];
    service: Services[];
    client: Client[];
    team: Team[]
  };
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};



const lastSectionSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Theme2Homepage: React.FC<Theme2Props> = ({ data }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const productImageUrl = process.env.REACT_APP_IMAGE_URL + '/products/';
  const profileImageUrl = process.env.REACT_APP_IMAGE_URL + '/team/';
  const serviceImageUrl = process.env.REACT_APP_IMAGE_URL + '/service/';
  const clientsImageUrl = process.env.REACT_APP_IMAGE_URL + '/client/';

  const rootKeys = Object.keys(data);
  // Extract the keys from the 'template' object
  const templateKeys = Object.keys(data.template);
  // Find specific keys from the data object
  const keysToFind = ['about', 'products', 'team', 'service'];

  // const foundKeys: string[] = [];
  // foundKeys.push('home');

  // keysToFind.forEach(key => {
  //   if (templateKeys.includes(key)) {
  //     foundKeys.push(key);
  //   } else if (rootKeys.includes(key)) {
  //     foundKeys.push(key);
  //   }
  // });

  // foundKeys.push('contact');

  const hederData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    templateId: data.template.templateId,
  }

  const footerData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    footer: data.template.footer,
    templateId: data.template.templateId,
  }

  const [showMore, setShowMore] = useState(false);

  // const displayedProducts = showMore ? data.products : data.products.slice(0, 4);
  const displayedProducts = data.products;

  if (!data) {
    return <div className="text-center p-8">Loading...</div>;
  }

  return (
    <>
      <Header hederData={hederData}/>
      <style>
        {`
      .slick-dots li button:before {
        font-size: 13px; /* Adjust dot size */
        color: blue; /* Default dot color */
      }
          
      .slick-dots li.slick-active button:before {
        color: ${ThemeColor}; /* Active dot color */
      }
      .theme-color{
        color: ${data.template.header.themecolore} !important
      }
      .theme-bg-color{
        background-color: ${data.template.header.themecolore}
      }
      `}
      </style>

      <section id="home" className="relative">
        <div className="relative-container container bg-cover bg-right bg-custom-white-opacity mx-auto px-4 py-20 flex flex-col items-start text-start text-black"
          style={{
            backgroundImage: `url(${imageUrl}${data.template.section.banner})`
          }}
        >
          <h1 className="z-10 font-pt-serif text-[#3c424f] text-4xl md:text-6xl font-bold mb-4 w-auto lg:w-1/2 mb-2">{data.template.section.title}</h1>
          <p className="z-10 font-sans text-[#686b77] leading-4 text-lg md:text-xl mb-6 w-auto lg:w-1/2 mt-2">{data.template.section.description}</p>
          <a href="#" className={`z-10 theme-bg-color hover:bg-[#3c424f] text-white font-semibold rounded py-3 px-10 mt-5`}>Read More</a>
        </div>
      </section>

      {/* About Us Section */}
      <div className='relative' id="about">

        <div className='main-background-image' style={{
          backgroundImage: `url(${About})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `contain`,
          backgroundPosition: `left top`,
          zIndex: -1,
        }}>
          <div id="about-us" className="relative z-10 lg:px-[89.5px] mx-auto px-4 py-16">
            <div className="text-center">
              <h2 className={`z-10 font-pt-serif font-semibold text-4xl text-[#3c424f] mb-4`}>About Us</h2>
              <span className="z-10 tm-sectiontitle-divider">
                <img alt="section divider" data-pagespeed-url-hash="1761756702" src={sectionDivider} />
              </span>
              <p className="z-10 open-serif-custom text-[#686b77] text-[16px] max-w-lg mx-auto">
                {data && data.template && data.template.about && data.template.about.description? data?.template?.about?.description?.slice(0, 60) : null }
              </p>

            </div>

            <div className="relative">
              <div className={`relative-container z-10 lg:h-[30rem] shadow-xl  p-7 lg:p-[70px] grid grid-cols-1 md:grid-cols-2 gap-8 mt-16`} style={{
                backgroundImage: `url(https://thememarch.com/demo/html/munu/munu/assets/images/about-block-background.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center right',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
              }}>
                <div className="z-10">
                  <h3 className={`font-pt-serif text-3xl font-bold mb-4 text-[#3c424f]`}>{data.template.about.title}</h3>
                  <p className={`open-serif-custom text-lg text-[#686b77]`}>
                    {data?.template?.about?.description}
                  </p>
                  <br />
                  <a href="#" className={`text-white font-semibold rounded py-3 px-10 mt-5`}
                    style={{
                      color: 'white',
                      backgroundColor: ThemeColor,
                    }}
                  >Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="service" className="py-16 bg-white font-['Open_Sans']">
        <div className="container mx-auto px-4">
          {/* Section Title */}
          <div className="text-center">
            <h2 className={`font-pt-serif font-bold text-4xl leading-5 text-[#3c424f] mb-4`}>Our Services</h2>
            <span className="tm-sectiontitle-divider">
              <img alt="section divider" data-pagespeed-url-hash="1761756702" src={sectionDivider} />
            </span>
            <p className="text-gray-600 text-base max-w-lg mx-auto mb-14">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint veniam maxime aliquid id optio odio facilis aperiam pariatur labore iusto?
            </p>
          </div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 m-7">
            {data?.service?.length > 0 ? (
              data.service.map((service, index) => (
                <div
                  key={index}
                  className="bg-white border border-gray-200 rounded-lg shadow-md p-6 text-center"
                >
                  {/* Icon */}
                  <img
                    // src={service.serviceImage}
                    src={`${serviceImageUrl}${service.serviceImage}`}
                    alt={service.name}
                    className="mx-auto mb-4"
                  />
                  {/* Title */}
                  <h3 className={`text-xl font-semibold mb-2 text-[#3c424f]`}>
                    {service.name}
                  </h3>
                  {/* Description */}
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  {/* Button */}
                  {/* <button
                    className={`bg-[${BGColor}] text-[#3c424f] border border-[#3c424f] py-2 px-4 rounded hover:theme-bg-color hover:text-white transition duration-300`}
                  >
                    Read more
                  </button> */}
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center w-full h-64">
                <p className="text-xl text-gray-500 text-center font-semibold">
                  No services available.
                </p>
              </div>
            )}
          </div>

        </div>
      </section>




      {/* Product section */}
      <div className="relative" id="products">
  <div
    className="main-product-section"
    style={{
      backgroundImage: `url(https://thememarch.com/demo/html/munu/munu/assets/images/products-bg-shape.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      backgroundPosition: 'center center',
    }}
  >
    <div className="container mx-auto px-4 mb-9 relative z-10">
      <div className="text-center">
        <h2 className={`font-pt-serif font-semibold text-4xl text-[${TitleColor}] mb-4`}>Our Latest Products</h2>
        <span className="tm-sectiontitle-divider">
          <img alt="section divider" data-pagespeed-url-hash="1761756702" src={sectionDivider} />
        </span>
        <p className="text-gray-600 text-base max-w-lg mx-auto mb-3">
          Lorem ipsum dolor sit amet consectetur adipiscing elit, per sed do eiusmod tempor sit amet elit inuning ut sed sittem do eiusmod.
        </p>
      </div>
      <Slider {...settings}>
        {data.products.map((product) => (
          <div key={product._id} className="w-full p-6 flex flex-col flex-grow flex-shrink">
            <a href="#" className="">
              <div className="flex-1 bg-gray-100 text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                {/* Fixed size for the image container */}
                <div className="w-full h-64 overflow-hidden">
                  <img
                    src={`${productImageUrl}${product.productImage}`}
                    alt={product.name}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              </div>
              <div className="flex-none mt-6 text-left">
                {/* Fixed size for product name and description */}
                <h5 className="text-gray-900 text-lg font-bold truncate">{product.name}</h5>
                <p className="text-gray-500">{product.price}</p>
                <p className="text-gray-600 mb-6 overflow-hidden max-h-16">{product.description}</p>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  </div>
</div>



      {/* Pricing and befor & after section */}
      <section className="bg-[#fdf4f3] text-[#686b77] font-pt-serif">
        <div className="container mx-auto">
          <div className="flex flex-wrap"> 
            {/* Services Price Section */}
            <div className="w-full lg:w-1/2 p-7 mt-10">
              <div className="p-6">
                <h3 className="text-[#3c424f] text-3xl font-semibold mb-4">Services price</h3>
                <p className="text-gray-600 mb-6 font-sans text-[#686b77]">
                  Cosmetics applied to the face to enhance its appearance are often called make-up or
                  makeup. Makeup services price below:
                </p>
                <ul className={`mb-6 font-sans text-[#686b77]`}>
                  {data?.service?.map((service, index) => (
                    <li
                      key={index}
                      className="flex justify-between border-b border-gray-200 py-2"
                    >
                      <span>{service?.name}</span>
                      <span> {service && service.price ? "$"+service?.price?.toFixed(2) : null}</span>
                    </li>
                  ))}
                </ul>

                {/* <a
                href="#appointment-area"
                className="tm-button hash-scroll-link bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-600 transition-colors duration-300"
              >
                Book now
              </a> */}
              </div>
            </div>

            {/* Before and After Services Section */}
            <div className="w-full lg:w-1/2 lg:p-7 lg:mt-10">
              <div className="p-6">
                <h3 className="text-[#3c424f] text-3xl font-semibold mb-4">Before after Services</h3>
                {/* Parent container with group class for hover control */}
                <div className="relative overflow-hidden rounded-lg group">
                  {/* Before Text */}
                  <span className="absolute top-2 left-2 text-white font-bold text-xl bg-black bg-opacity-50 px-2 py-1 rounded">
                    Before
                  </span>

                  {/* Before Image */}
                  <img
                    src={BeforImage}
                    alt="before image"
                    className="w-full transition-opacity duration-500 opacity-100 hover:opacity-0"
                  />

                  {/* After Image */}
                  <img
                    src={AfterImage}
                    alt="after image"
                    className="w-full absolute top-0 left-0 transition-opacity duration-500 opacity-0 group-hover:opacity-100"
                  />

                  {/* After Text */}
                  {/* Use fixed positioning relative to the parent and ensure it's hidden by default */}
                  <span className="absolute top-2 right-2 text-white font-bold text-xl bg-black bg-opacity-50 px-2 py-1 rounded z-10 opacity-0 group-hover:opacity-100">
                    After
                  </span>
                </div>
              </div>
            </div>



          </div>
        </div>
      </section>

      {/* Our Team section */}
      <div className="container mx-auto px-4 mb-16 lg:mb-24 mt-9" id="team">
        {/* Section Title */}
        <div className="text-center">
          <h2 className={`font-pt-serif font-extrabold text-[36px] text-[#3c424f] mb-4`}>Our Happy Team</h2>
          <span className="tm-sectiontitle-divider">
            <img alt="section divider" data-pagespeed-url-hash="1761756702" src={sectionDivider} />
          </span>
          <p className="text-[#686b77] text-base max-w-lg mx-auto mb-10">
            Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad teimpor sittem elit inuning ut sed sittem do eiusmod.
          </p>
        </div>
        <Slider {...settings}>

          {data && data.team && data.team != null? 
          
          data.team.map((member) => (
            <div key={member._id} className="w-full p-6 flex flex-col flex-grow flex-shrink">
              <a href="#" className="">
                <div className="flex-1 bg-gray-100 text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                  {/* Dynamic image rendering */}
                  <img
                    src={`${profileImageUrl}${member.profileImage}`}
                    alt={member.name}
                    className="w-full object-cover object-center"
                  />
                </div>
                <div className="flex-none mt-6 text-left">
                  <h5 className="text-gray-900 text-lg font-bold">{member.name}</h5>
                  <p className="text-gray-500">{member.description}</p>
                </div>
              </a>
            </div>
          ))
          
          : null}
        </Slider>
      </div>

      {/* last Scetion */}

      <div className="bg-[#fdf4f3] py-24">
  <div className="container mx-auto px-4">
    {data && data.client && data.client.length > 0 ? (
      <Slider {...lastSectionSettings}>
        {data.client.map((client) => (
          <div key={client.id} className="px-2">
            <div className="bg-white p-8 flex justify-center items-center shadow-md">
              {/* Set fixed width and height for the image container */}
              <div className="w-32 h-32 flex justify-center items-center">
                {/* Set object-cover to ensure the image scales properly while maintaining its aspect ratio */}
                <img 
                  src={`${clientsImageUrl}${client.clientImage}`} 
                  alt="client-image" 
                  className="w-full h-full object-cover" 
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    ) : (
      <p>No clients available.</p>
    )}
  </div>
</div>



      {/* Footer Scetion */}

      <Footer footerData={footerData} />

    </>
  );
};

const TruncatedText: React.FC<{ text: string; maxLength: number }> = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return <p className="text-gray-600">{text}</p>;
  }

  return (
    <div>

      <p className={classNames('text-gray-600', { 'line-clamp-3': !isExpanded })}>
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </p>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-pink-500 hover:text-pink-500 focus:outline-none" >
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
    </div>

  );
};

export default Theme2Homepage;