import React from 'react';
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaMobileAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

interface ThemePageProps {
  footerData: {
    logo: any;
    title: any;
    footer: any;
    themeColor: string;
    templateId: string;
  };
}

const Footer: React.FC<ThemePageProps> = ({ footerData }) => {

  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  let BGColor: string;
  let TextColor: string;
  let ThemeColor: string;
  let TitleColor: string;
  let fontFamily: string;

  const location = useLocation();
  const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  let menus = [];

  switch (footerData?.templateId) {
    case '2':
      BGColor = "#FFFFFF";
      TextColor = "#686b77";
      fontFamily = "Arial, sans-serif";
      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "products": "Products" },
        { "team": "Team" },
        { "service": "Service" },
        { "contact": "Contact" },
      ];
      break;
    //... other cases
    default:
      BGColor = "#1D2024";
      TextColor = "#FFFFFF";
      fontFamily = "Poppins, sans-serif";
      menus = [
        { "home": "Home" },
        { "about": "About" },
        { "service": "Service" },
        { "team": "Doctors" },
        { "contact": "Contact" },
      ];
      break;
  }

  ThemeColor = footerData?.themeColor;

  return (
    <>
      <style>
        {`
          .footer {
            color: ${TextColor};
            background-color: ${BGColor};
            font-family: ${fontFamily};
          }
          .social-icons {
            color: ${TextColor};
            background-color: ${BGColor};
            border-color: ${TextColor};
          }
          .social-icons:hover {
            color: ${BGColor};
            background-color: ${ThemeColor};
            border-color: ${BGColor};
          }
          .quick-link {
            color: ${TextColor};
            background-color: ${BGColor};
            border-color: ${TextColor};
          }
          .quick-link ul li:hover {
            color: ${ThemeColor};
          }
        `}
      </style>

      <footer id='contact-us' className={`footer py-10`}>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Logo and Description */}
            <div>
              <h1 className={`text-3xl font-bold text-[${TextColor}]`}>
                About Us
              </h1>
              <p className="mt-4">
                {footerData.footer.about}
              </p>
              <div className="flex space-x-4 mt-4 text-center">
                <a href={footerData.footer.twitter} className={`border rounded-full social-icons p-3 hover:text-[${BGColor}] hover:bg-[${ThemeColor}]`}>
                  <FaTwitter size={20} />
                </a>
                <a href={footerData.footer.facebook} className={`border rounded-full social-icons p-3 hover:text-[${BGColor}] hover:bg-[${ThemeColor}]`}>
                  <FaFacebookF size={20} />
                </a>
                <a href={footerData.footer.linkedin} className={`border rounded-full social-icons p-3 hover:text-[${BGColor}] hover:bg-[${ThemeColor}]`}>
                  <FaLinkedinIn size={20} />
                </a>
                <a href={footerData.footer.instagram} className={`border rounded-full social-icons p-3 hover:text-[${BGColor}] hover:bg-[${ThemeColor}]`}>
                  <FaInstagram size={20} />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div className='md:text-center'>
              <h2 className={`text-xl font-bold`}>Quick Links</h2>
              <div className='w-1/2 lg:mx-auto md:mx-auto text-center quick-link'>
                <ul className="w-1/2 lg:mx-auto md:mx-auto mt-4 space-y-2 text-left">
                  {menus.map((menu, index) => {
                    const [key, value] = Object.entries(menu)[0];
                    return (
                      <li key={index}>
                        <a href={`#${key}`} className={``}>{value}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* Contact Info */}
            <div id="contact">
              <h4 className={`text-xl font-bold text-[${TextColor}] mb-4`}>Contact Info</h4>
              <div className={`flex items-center mb-4 text-[${TextColor}]`}>
                <FaMobileAlt className={`text-[${TextColor}] text-2xl mr-4`} />
                <div>
                  <h3 className={`text-lg font-semibold text-[${TextColor}]`}>Phone Number</h3>
                  <p>{footerData.footer.phone}</p>
                </div>
              </div>
              <div className={`flex items-center mb-4 text-[${TextColor}]`}>
                <FaEnvelope className={`text-[${TextColor}] text-2xl mr-4`} />
                <div>
                  <h3 className={`text-lg font-semibold text-[${TextColor}]`}>Email Address</h3>
                  <p>{footerData.footer.email}</p>
                </div>
              </div>
              <div className={`flex items-center text-[${TextColor}]`}>
                <FaMapMarkerAlt className={`text-[${TextColor}] text-2xl mr-4`} />
                <div>
                  <h3 className={`text-lg font-semibold text-[${TextColor}]`}>Office Address</h3>
                  <p>{footerData.footer.address}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Copyright Section */}
          <div className="mt-8 text-center text-sm">
            <p className="text-[${TextColor}]">
              Copyright © {new Date().getFullYear()} {footerData.footer.companyName} - Powered by Net4Surge {footerData.footer.poweredBy}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
