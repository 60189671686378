import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import Header from './Comman/Header';
import Footer from './Comman/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

interface Product {
  _id: string;
  userId: string;
  name: string;
  description: string;
  productImage: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}
// interface Client {
//   _id: string; // Ensure _id is included
//   clientImage: string;
  
// }

interface Services {
  serviceImage: any;
  _id: string;
  name: string;
  description: string;
  price: number;
  
}
interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}
// interface Client{
//   _id:string;
//   clientImage:string
// }
interface Client{
  id:string;
  clientImage:string;
}
// interface achievements{
//   _id: string;
//   name: string;
//   counter: number;

// };



interface ThemeData {
  _id: string;
  userId: string;
  templateId: string;
  header: {
    themecolore: string;
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    logo: string | null;
    _id: string;
  };
  section: {
    title: string;
    description: string;
    banner: string | null;
    _id: string;
  };

  footer: {
    themecolore: string;
    about: string;
    phone: string;
    email: string;
    address: string;
    instagram: string;
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  domainName: string;
}

interface ThemePageProps {
  data: {
    service: Services[];
    template: ThemeData;
    products: Product[];
    team: Team[];
    client:Client[];
    achievement:[{
      _id: string;
      userId: string;
      title1: string;
      counts1: number;
      title2: string;
      counts2: number;
      title3: string;
      counts3: number;
      title4: string;
      counts4: number;
  
  }];
  };
}


const ThemePage: React.FC<ThemePageProps> = ({ data }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const productImageUrl = process.env.REACT_APP_IMAGE_URL + '/products/';
  const serviceImageUrl = process.env.REACT_APP_IMAGE_URL + '/service/';
  const teamImageUrl = process.env.REACT_APP_IMAGE_URL + '/team/';
  const clientImageUrl = process.env.REACT_APP_IMAGE_URL + '/client/';
  const achievementImageUrl = process.env.REACT_APP_IMAGE_URL + '/achievement/';
  const [showMore, setShowMore] = useState(false);

  const displayedProducts = showMore ? data.products : data.products.slice(0, 4);
  const headerBgColor = "bg-[#008B8B]";

  console.log(data.achievement);


  const hederData: any = {
    templateId: data.template.templateId,
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
  };

  const footerData: any = {
    templateId: data.template.templateId,
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    footer: data.template.footer,
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: <FaChevronLeft color={data.template.header.themecolore} size="2rem" className='absolute top-1/2 left-[-50px] transform -translate-y-1/2' />, // Custom previous arrow
    nextArrow: <FaChevronRight color={data.template.header.themecolore} size="2rem" />, // Custom next arrow
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // const rootKeys = Object.keys(data);
  // const templateKeys = Object.keys(data.template);
  // const keysToFind = ['about', 'products', 'team', 'service'];

  // const foundKeys: string[] = [];
  // foundKeys.push('home');

  // keysToFind.forEach((key) => {
  //   if (templateKeys.includes(key as keyof ThemeData)) {
  //     const value = data.template[key as keyof ThemeData];
  //     if (value && (typeof value === 'string' || Array.isArray(value))) {
  //       if (value.length > 0) {
  //         foundKeys.push(key);
  //       }
  //     }
  //   } else if (rootKeys.includes(key as keyof ThemePageProps['data'])) {
  //     const value = data[key as keyof ThemePageProps['data']];
  //     if (value && (typeof value === 'string' || Array.isArray(value))) {
  //       if (value.length > 0) {
  //         foundKeys.push(key);
  //       }
  //     }
  //   }
  // });

  // foundKeys.push('contact');

  return (
    <>
      <Header hederData={hederData}/>

      <section id='home' className="text-gray-600 body-font -mt-4">
        <div className="relative w-full h-auto max-w-screen-xxl max-h-screen px-5 py-8 flex items-center mx-auto">
          <div className="relative w-full h-auto flex items-center justify-center">
            <img
              className="object-cover w-full h-full max-h-96 rounded"
              alt="hero"
              src={data.template.section.banner ? `${imageUrl}${data.template.section.banner}` : "https://dummyimage.com/720x600"}
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-4 bg-black bg-opacity-50 rounded">
              <h1 className="title-font sm:text-6xl text-4xl mb-4 font-medium text-white">
                {data.template.section.title}
              </h1>
              <p className="mb-8 leading-relaxed font-semibold">
                {data.template.section.description}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div id='products' className="container mx-auto px-6 py-10 bg-gray-200">
  <h1 className='text-sm font-bold text-center text-blue-600'>Popular Courses</h1>
  <h2 className="text-3xl font-bold text-center text-gray-900 mb-10 font-lexend">
    Choose Our <span className="text-blue-600 underline">Top Courses</span>
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
    {data.service.map((service) => (
      <div key={service._id} className="bg-white shadow-xl rounded-lg overflow-hidden">
  <img className="w-full h-full max-h-[300px] object-cover" src={`${serviceImageUrl}${service.serviceImage}`} alt={service.name} />
  <div className="p-4">
    <h3 className="text-lg font-semibold mb-2 bg-blue-200 text-gray-800 px-4 py-2 rounded-lg inline-block mb-6">
      {service.name}
    </h3>
    <p className="text-gray-700 font-semibold mb-12 sm:mb-12 text-base sm:text-lg leading-relaxed break-words max-w-full max-h-full" 
       style={{ fontFamily: "'Lexend', sans-serif" }}>
      {service.description}
    </p>
  </div>
</div>

    ))}
  </div>
</div>


    {/*  Achivment About*/}
      <div id='about' className="container mx-auto px-6 py-8 bg-gray-200">
        <h2 className="text-center text-blue-600 text-1xl font-bold uppercase mb-2 font-lexend">Some Fun Fact</h2>
        <h3 className="text-center text-4xl font-bold text-gray-800 mb-8 font-lexend">
          Our Great <span className="text-blue-600 underline">Achievement</span>
        </h3>
        <div className="flex flex-wrap justify-center gap-12">
          <div className="bg-blue-500 text-white flex items-center py-4 px-6 w-64 min-h-[120px]">
            <div className="text-3xl mr-4">😊</div>
            <div className="flex flex-col justify-center">
              <div className="text-2xl font-bold">{data?.achievement[0]?.counts1}</div>
              <div className="text-sm font-semibold">{data?.achievement[0]?.title1}</div> 

            </div>
          </div>
          <div className="bg-blue-500 text-white flex items-center py-4 px-6 w-64 min-h-[120px]">
            <div className="text-3xl mr-4">📚</div>
            <div className="flex flex-col justify-center">
              <div className="text-2xl font-bold">{data?.achievement[0]?.counts2}</div>
              <div className="text-sm font-semibold">{data?.achievement[0]?.title2}</div>
            </div>
          </div>
          <div className="bg-blue-500 text-white flex items-center py-4 px-6 w-64 min-h-[120px]">
            <div className="text-3xl mr-4">🏆</div>
            <div className="flex flex-col justify-center">
              <div className="text-2xl font-bold">{data?.achievement[0]?.counts3}</div>
              <div className="text-sm font-semibold">{data?.achievement[0]?.title3}</div>
            </div>
          </div>
          <div className="bg-blue-500 text-white flex items-center py-4 px-6 w-64 min-h-[120px]">
            <div className="text-3xl mr-4">🎓</div>
            <div className="flex flex-col justify-center">
              <div className="text-2xl font-bold">{data?.achievement[0]?.counts4}</div>
              <div className="text-sm font-semibold">{data?.achievement[0]?.title4}</div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-blue-50 py-12">
  <div className="container mx-auto px-4 md:px-6 flex flex-col md:flex-row items-center justify-between">
    <div className="mb-8 md:mb-0 md:w-1/2">
      <h2 className="text-1xl font-bold text-blue-900 mb-2">Our Partner</h2>
      <h2 className="text-4xl font-bold text-blue-900">
        Trusted Company <br />Around The World!
      </h2>
      <p className="text-blue-700 mt-4">
        Lorem ipsum dolor sit amet consectetur adipiscing elit <br />sed eiusmod
        tempor incididunt.
      </p>
    </div>
    <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:w-1/2">
      {data.client.map((client) => (
        <div
          key={client.id}
          className="bg-white p-4 flex justify-start items-center shadow-lg rounded-md"
        >
          <img src={`${clientImageUrl}${client.clientImage}`} className="w-auto h-12" alt={`Image`} />
        </div>
      ))}
    </div>
  </div>
</section>


      <section id='team' className="py-10 bg-gray-200">
      <div className="container mx-auto px-4">
      <p className="text-blue-600 font-extrabold bold text-lg text-center">Our Instructor</p>
      
      <h2 className="text-4xl font-bold text-gray-800 mb-8 text-center">
        Our Expert <span className="text-blue-600 underline">Instructors</span>
      </h2>
        
        {/* Slider implementation */}
        <Slider {...sliderSettings}>
  {data.team.map((member, index) => (
    <div key={index} className="p-4">
        <div className="bg-white shadow-2xl rounded-lg overflow-hidden max-w-sm mx-auto"> {/* Adjust card width */}
          <div className="relative w-full h-[300px]"> {/* Set fixed height for image container */}
            <img
              src={`${teamImageUrl}${member.profileImage}`}
              alt={member.name}
              className=" mt-6 absolute inset-0 w-full h-full object-contain transition-transform transform hover:scale-105" // Ensure the image fits within the container
            />
          </div>

        <div className="text-center p-4"> {/* Adjust padding */}
          <h3 className=" mt-4 text-lg font-bold text-blue-600">{member.name}</h3>
          <div className="flex justify-center items-center my-4">
            <hr className="border-t-2 border-blue-600 w-1/5" />
            <span className="mx-2 text-blue-600 text-2xl">•</span>
            <hr className="border-t-2 border-blue-600 w-1/5" />
          </div>
          <p className="text-gray-900 font-semibold mb-3">{member.description}</p>
          <div className="flex gap-x-6 text-xl text-gray-900">
            <p className="flex items-center ml-10">
              {/* <i className="mr-1"></i> {member.} Courses */}
            </p>
            <p className="flex items-center ml-2">
              {/* <i className="mr-1"></i> <span>{member.} Students</span> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  ))}
</Slider>



      </div>
    </section>

      <Footer footerData={footerData} />
    </>
  );
};

export default ThemePage;
