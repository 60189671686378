import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { message, Spin, theme } from 'antd';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Theme1 from './Components/Theme1';
import Theme2 from './Components/Theme2';
import Theme3 from './Components/Theme3';
import Theme4 from './Components/Theme4';
import Theme5 from './Components/Theme5';
import NotFound from './Components/Notfound';
import { useEffect, useState } from 'react';

// Use environment variables
export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

interface Product {
  _id: string;
  userId: string;
  productImage: string;
  name: string;
  description: string;
  price: number;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}

interface Services {
  _id: string;
  name: string;
  description: string;
  price: number;
  serviceImage: string;
}

interface Client {
  id: string;
  clientImage: string;
}

// interface Achievement{
//   _id: string;
//   userId: string;
//   title1: string;
//   counts1: number;
//   title2: string;
//   counts2: number;
//   title3: string;
//   counts3: number;
// }

interface ThemeData {
  template: {
    _id: string;
    userId: string;
    templateId: string;
    header: {
      title: string;
      themecolore: string;
      description: string;
      buttonText: string;
      buttonLink: string;
      logo: string | null;
      _id: string;
    };
    section: {
      title: string;
      description: string;
      banner: string | null;
      _id: string;
    };
    about: {
      title: string;
      description: string;
      image: string | null;
      _id: string;
    };
    footer: {
      about: string;
      phone: string;
      email: string;
      address: string;
      instagram: string;
      facebook: string;
      twitter: string;
      linkedin: string;
      themecolore: string;

    };
    domainName: string;
    metaName: string;
    metaTitle: string;
    metaDescription: string;

  };
  products: Product[];
  team: Team[];
  service: Services[];
  client: Client[];
  achievement: [{
    _id: string;
    userId: string;
    title1: string;
    counts1: number;
    title2: string;
    counts2: number;
    title3: string;
    counts3: number;
    title4: string;
    counts4: number;
  }]
};

const AppContent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [themeData, setThemeData] = useState<ThemeData | null>(null);
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [seoData, setSeoData] = useState({
    metaTitle: 'Default Title',
    metaDescription: 'Default description',
    metaName: 'default, keywords',
  });
  const location = useLocation();
  const navigate = useNavigate();

  const fetchDomain = async (domainName: string) => {
    try {
      const response = await API.post('/find/domain', { domainName });
      const fetchedData = response.data as ThemeData; // Cast the response data to ThemeData
      setTemplateId(fetchedData.template.templateId);
      setThemeData(fetchedData);
      setSeoData({
        metaTitle: fetchedData.template.metaTitle || 'Default Title',
        metaDescription: fetchedData.template.metaDescription || 'Default description',
        metaName: fetchedData.template.metaName || 'default, keywords',
      });

    } catch (error: any) {
      message.error(error?.response?.data?.message || 'An error occurred while fetching data');
      setTemplateId(null);
      setThemeData(null);
      navigate('/notfound');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const pathArray = location.pathname.split('/');
    const domain = process.env.REACT_APP_ENV === 'local'
      ? pathArray[pathArray.length - 1]
      : window.location.hostname.split('.')[0];

    if (domain) {
      fetchDomain(domain);
    } else {
      setTemplateId(null);
      setThemeData(null);
      setLoading(false);
    }
  }, [location.pathname]);

  const renderComponent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center min-h-screen">
          <Spin size="large" tip="Loading..." />
        </div>
      );
    }

    if (!themeData || !templateId) {
      return <NotFound />;
    }
    <Helmet>
      <title>{seoData.metaTitle}</title>
      <meta name="description" content={seoData.metaDescription} />
      <meta name="keywords" content={seoData.metaName} />
    </Helmet>

    switch (templateId) {
      case '1':
        return <Theme1 data={themeData} />;
      case '2':
        return <Theme2 data={themeData} />;
      case '3':
        return <Theme3 data={themeData} />;
      case '4':
        return <Theme4 data={themeData} />;
      case '5':
        return <Theme5 data={themeData} />;
      default:
        return <NotFound />;
    }
  };

  return renderComponent();
};

const App = () => {



  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<AppContent />} />
        <Route path="/notfound" element={<NotFound />} /> */}

        {/* local */}
        {/* <Route path="/" element={<NotFound />} /> */}
        <Route path="*" element={<AppContent />} />
      </Routes>
    </Router>

  );
};

export default App;
