import React, { useState } from 'react';
import Header from './Comman/Header';
import Footer from './Comman/Footer';
import { FaCirclePlus } from "react-icons/fa6";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';



interface Services {
  _id: string;
  name: string;
  description: string;
  price: number;
  serviceImage: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
  profileImage: string;
}

interface ThemeData {
  _id: string;
  userId: string;
  templateId: string;
  header: {
    title: string;
    themecolore: string;
    description: string;
    buttonText: string;
    buttonLink: string;
    logo: string | null;
    _id: string;
  };
  about: {
    title: string;
    description: string;
    image: string | null;
    _id: string;

  }
  section: {
    title: string;
    description: string;
    banner: string | null;
    _id: string;
  };

  footer: {
    about: string;
    phone: string;
    email: string;
    address: string;
    instagram: string;
    facebook: string;
    twitter: string;
    linkedin: string;
  };
  domainName: string;
}

interface ThemePageProps {
  data: {
    template: ThemeData;
    service: Services[];
    team: Team[];
  };
}

const ThemePage1: React.FC<ThemePageProps> = ({ data }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const serviceImageUrl = process.env.REACT_APP_IMAGE_URL + '/service/';
  const profileImageUrl = process.env.REACT_APP_IMAGE_URL + '/team/';
  const [expandedDescriptionIds, setExpandedDescriptionIds] = useState<string[]>([]);
  // console.log(data.template.header.themecolore);

  // const headerBgColor = data.template.header.themecolore;

  const toggleDescription = (id: string) => {
    if (expandedDescriptionIds.includes(id)) {
      setExpandedDescriptionIds(expandedDescriptionIds.filter(expandedId => expandedId !== id));
    } else {
      setExpandedDescriptionIds([...expandedDescriptionIds, id]);
    }
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: <FaChevronLeft color={data.template.header.themecolore} size="2rem" className='absolute top-1/2 left-[-50px] transform -translate-y-1/2' />, // Custom previous arrow
    nextArrow: <FaChevronRight color={data.template.header.themecolore} size="2rem" />, // Custom next arrow
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const hederData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    templateId: data.template.templateId,
  }

  const footerData: any = {
    themeColor: data.template.header.themecolore,
    logo: data.template.header.logo,
    title: data.template.header.title,
    footer: data.template.footer,
    templateId: data.template.templateId,
  }
  const rootKeys = Object.keys(data);
  const templateKeys = Object.keys(data.template);
  const keysToFind = ['about', 'service', 'team'];

  // const foundKeys: string[] = [];
  // foundKeys.push('home');

  // keysToFind.forEach(key => {
  //   if (templateKeys.includes(key)) {
  //     foundKeys.push(key);
  //   } else if (rootKeys.includes(key)) {
  //     foundKeys.push(key);
  //   }
  // });

  // foundKeys.push('contact');

  return (
    <>
      <Header hederData={hederData} />
      <style>
        {`
        .theme-color{
          color: ${data.template.header.themecolore} !important
        }
        .slick-arrow slick-prev {
          color: black !important;
        }
        .theme-bg-color{
          background-color: ${data.template.header.themecolore}
        }
        .theme-border-color{
          border-color: ${data.template.header.themecolore}
        }
        .slider-container .slick-slide {
          width: 70% !important;
          display: block !important;
        }
        `}
      </style>
      <section id="home" className="relative text-white body-font">
        <div className="relative">
          {/* Background Image */}
          <img
            className="object-cover w-full h-96 sm:h-full rounded"
            alt="hero"
            src={
              data.template.section.banner
                ? `${imageUrl}${data.template.section.banner}`
                : "https://dummyimage.com/720x600"
            }
          />
          {/* Overlay Container */}
          <div className="absolute inset-0 flex flex-col justify-center items-start text-left bg-opacity-40 px-6 sm:px-8">
            {/* Title */}
            <h1 className="title-font text-2xl sm:text-5xl mb-4 font-extrabold text-black max-w-full sm:max-w-lg">
              {data.template.section.title}
              <br className="hidden lg:inline-block" />
            </h1>
            {/* Description */}
            <p className="mb-5 leading-relaxed text-gray-700 text-base sm:text-lg max-w-full sm:max-w-lg">
              {data.template.section.description}
            </p>
            {/* Button */}
            <div className="flex justify-start">
              <a
                target="_blank"
                href={data.template.header.buttonLink}
                className={`inline-flex theme-bg-color text-white border-0 py-2 px-4 sm:px-6 focus:outline-none hover:bg-teal-500 rounded text-sm sm:text-lg`}
              >
                {data.template.header.buttonText}
              </a>
            </div>
          </div>
        </div>
      </section>




      <div id="about" className="flex flex-col lg:flex-row items-center lg:items-start bg-white p-8 lg:p-12">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:mr-8">
          <img
            src={data.template.about.image ? `${imageUrl}${data.template.about.image}` : "https://dummyimage.com/720x600"}
            alt="Doctor"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-1/2 text-center lg:text-left">
          <p className="theme-color font-bold mb-2">HAS BEEN WORKING SINCE 2000</p>
          <h2 className="text-3xl lg:text-4xl font-bold mb-4">
            {data.template.about.title}
          </h2>
          <p className="text-gray-600 mb-8">
            {data.template.about.description}
          </p>
          <p className="text-gray-600 mb-8">
            {data.template.about.description}
          </p>

          {/* Video Intro Section */}
          {/* <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <div className="flex items-center">
            <div className="bg-teal-500 text-white p-3 rounded-full mr-4">
              <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20">
                <path d="M8 5v10l7-5-7-5z" />
              </svg>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">LET'S SEE OUR INTRO VIDEO</h3>
              <p className="text-gray-600">If your smile is not becoming to you, then you should be coming to me!</p>
            </div>
          </div>
        </div> */}
        </div>
      </div>

      <section id='service' className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto text-center">
          <div className='relative'>
            <h2 className="text-4xl font-bold text-gray-800">
              OUR <span className="theme-color" >SERVICES</span>
            </h2>
            <span className="absolute inset-x-0 bottom-[-0.5rem] flex justify-center">
              <hr className="w-32 theme-border-color border-t-2" />
            </span>
          </div>
          <p className="mt-4 text-gray-500 max-w-2xl mx-auto">
            While mirth large of on front. Ye he greater related adapted proceed entered an.
            Through it examine express promise no. Past add size game cold girl off how old.
          </p>

          <div className="px-4 mt-12  md:px-8 lg:px-32 grid gap-8 sm:grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto">
  {/* Outdoor Checkup */}
  {data.service.map((service) => {
    const isExpanded = expandedDescriptionIds.includes(service._id);
    const productDescription = isExpanded ? service.description : service.description.slice(0, 100);
    return (
      <div
        key={service._id}
        className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center w-full"
      >
        {/* Image */}
        <img
          src={`${serviceImageUrl}${service.serviceImage}`}
          alt="Operation Theatre icon"
          className="h-52 w-full mb-4 mx-auto rounded-lg"
        />

        <div>
          <h3 className="text-lg font-bold text-gray-800 mb-4 text-center">{service.name}</h3>
          <hr />
          <ul className="text-left text-gray-600">
            <li className="flex items-center">
              {productDescription}
            </li>
          </ul>
        </div>
      </div>
    );
  })}
</div>


        </div>
      </section>


      <div id="team" className="container mx-auto my-10 text-center">
        <div className="relative">
          <h2 className="text-3xl font-bold mb-8 relative">
            MEET OUR <span className="theme-color">SPECIALISTS</span>
          </h2>
          <span className="absolute inset-x-0 bottom-[-0.5rem] flex justify-center">
            <hr className="w-32 theme-border-color border-t-2" />
          </span>
        </div>

        <p className="text-gray-600 mb-10 max-w-2xl mx-auto">
          While mirth large of on front. Ye he greater related adapted proceed entered an. Through it examine express promise no. Past add size game cold girl off how old.
        </p>
        <Slider {...sliderSettings} className='m-4 md:m-24'> {/* Smaller margin for mobile */}
          {data?.team?.map((member) => (
            <div
              key={member._id}
              className="shadow-lg rounded-lg p-4 md:p-6 m-2 md:m-4 bg-white"
              style={{ width: '100%' }}
            >
              {/* Image section */}
              <img
                className="w-full h-40 md:h-60 object-cover rounded-t-lg"
                src={`${profileImageUrl}${member.profileImage}`}
                alt={member.name}
              />

              {/* Decorative line and icon */}
              <div className="flex items-center justify-center bg-gray-50">
                <div className="flex-grow border-t-2 theme-border-color"></div>
                <div className="relative mx-4 p-1 rounded-full bg-white">
                  <div className="flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-white rounded-full absolute bottom-[-1rem] left-1/2 transform -translate-x-1/2 shadow-md">
                    <FaCirclePlus className='theme-color' size="2rem" />  {/* Adjusted size for mobile */}
                  </div>
                </div>
                <div className="flex-grow border-t-2 theme-border-color"></div>
              </div>

              {/* Text content */}
              <div className="px-2 md:px-4 py-4 text-center flex-grow">
                <div className="font-bold text-lg md:text-xl mb-2">{member.name}</div> {/* Responsive text size */}
                <p className="theme-color text-xs md:text-sm">{member.description}</p> {/* Smaller text for mobile */}
              </div>

              {/* Appointment button */}
              <div className="px-4 pt-0 pb-4 text-center">
                <div className="flex items-center justify-center">
                  <hr className="flex-grow border-gray-300" />
                  <button className="flex-shrink-0 mx-4 my-2 text-xs md:text-sm font-bold text-gray-500">
                    Book Appointment
                  </button>
                  <hr className="flex-grow border-gray-300" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>


      <Footer footerData={footerData} />
    </>
  );
};

export default ThemePage1;
